html,
body.cui,
#root {
  height: 100%;
  // --cui-spacing: 80px;
}

.cui .container,
.cui .container-fluid {
  padding-right: 5rem;
  padding-left: 5rem;
}

.cui .modal {
  background: rgba(172, 181, 189, 0.75);
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  z-index: 1;

  .main {
    margin-top: var(--cui-header-height);
    display: flex;
    flex-direction: column;
    flex: 1;
    // border: 1px solid red;
  }
  .footer {
    margin-top: 0;
    padding: 20px;
  }
}
